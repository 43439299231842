import { withPlugins, inview } from '@spon/plugins';

function MoveInView({ node, plugins: { inview } }) {
	/*
	 *		Variables
	 */

	inview.settings = {
		rootMargin: '0px 0px -20% 0px',
	};

	// watch some other nodes
	inview.observe([...node.querySelectorAll('[data-in-view]')], {
		enter: ({ target }) => {
			if (!target.classList.contains('has-moved')) {
				target.classList.add('has-moved'); // handled with CSS.
				const images = target.querySelectorAll('[data-image]');
				if (images) {
					images.forEach(img => {
						img.src = img.dataset.src;
					});
				}
			}
		},
	});
}
export default withPlugins(inview)(MoveInView);
